import { motion } from 'framer-motion';
import React , {useEffect, useState} from 'react';
import axios from 'axios';
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'

import Header from '../../components/HeaderJngl/Header';

import etherscan from '../../assets/icons/etherscanIcon.png';
import discord from '../../assets/icons/discordIcon.png';
import opensea from '../../assets/icons/openseaIcon.png';
import twitter from '../../assets/icons/twitterIcon.png';
import dex from '../../assets/icons/dexIcon.png';
import emailIcon from '../../assets/icons/emailIcon.png';
import tg from '../../assets/icons/tgIcon.png';

import banner from '../../assets/images/staking-banner.png'
import bannerMobile from '../../assets/images/staking-banner-mobile.png'
import logo from '../../assets/images/staking-logo.png'

import token1 from '../../assets/images/token-jngl.png'
import token2 from '../../assets/images/token-db.png'

import './MP.scss';
import { useWeb3Context } from '../../context'

import { toast } from 'react-toastify';
import WalletConnectProvider from '@walletconnect/web3-provider'

const marketplaceAbi = require('../../helper/marketplace.json')
const stakingAbi = require('../../helper/stakingOffchain.json')
const alphaAbi = require('../../helper/alpha.json')

let web3Modal;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
  },
}

if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true,
    providerOptions, // required
  })

  console.log(web3Modal)
}


export default function   Home() {
  const { web3Provider, connect, disconnect, address } = useWeb3Context()

  const [kongBalance, setKongBalance] = React.useState(0)
  const [kongBalanceRaw, setKongBalanceRaw] = React.useState(0)

  const [showModal, setShowModal] = React.useState(false)
  const [showUnstakeModal, setShowUnstakeModal] = React.useState(false)

  const stakingAddress = "0x579D27a47A270601b041F8B36e7F5CBC37508B40"

  const [stakeAmount, setStakeAmount] = React.useState(0)
  const [unstakeAmount, setUnstakeAmount] = React.useState(0)

  const [stakingBalance, setStakingBalance] = React.useState(0)
  const [earnedBalance, setEarnedBalance] = React.useState(0)
  const [claimedBalance, setClaimedBalance] = React.useState(0)
  const [stakingBalanceRaw, setStakingBalanceRaw] = React.useState(0)

  const [stakeMax, setStakeMax] = React.useState(0)

  // const [countDown, setCountDown] = React.useState(
  //   countDownDate - new Date().getTime()
  // ); 

  const [Listing, setListing] = React.useState([]);
  const base_uri = "https://api.supremekong.com";

  useEffect(async() => {
    //Runs only on the first render
    if(web3Provider){
      console.log('web3Provider')
      loadToken()
      handleRefreshBalance()

      const intervalId = setInterval(async () => {
        try {
          await loadEarned();
        } catch (err) {
          console.error(err);
        }
      }, 1000);

      // Clean up the interval when the component unmounts
      return () => clearInterval(intervalId);
    }else{
    }
  }, [web3Provider]);

  const [updateBalance, setUpdateBalance] = useState(0);

  const handleRefreshBalance = () => {
    // Increment the updateBalance state to trigger the balance update in Header
    setUpdateBalance(updateBalance + 1);
    console.log("called")
  };

  const [selectedImage, setSelectedImage] = React.useState();

  const handleItemClick = (item) => {
    setSelectedImage(item.image); // You may need to adjust this line depending on your data structure
  };

  const closeModal = () => {
    setShowModal(false)
    setShowUnstakeModal(false)
  }

  const openStake = () => {
    setShowModal(true)
  }

  const openUnstake = () => {
    setShowUnstakeModal(true)
  }

  const handleStakeAmountChange = (event) => {
    setStakeAmount(event.target.value);
  }

  const handleUnStakeAmountChange = (event) => {
    setUnstakeAmount(event.target.value);
  }

  const loadToken = async() => {
    console.log('each seond')
    const signer = web3Provider?.getSigner();
    const contract = new ethers.Contract("0x4c45bbec2ff7810ef4a77ad7bd4757c446fe4155", alphaAbi, signer);
    
    const stakingContract = new ethers.Contract(stakingAddress, stakingAbi, signer);
    
    const balance = await contract.balanceOf(address);
    const stakingBalance = await stakingContract.getStaked(address, 0);
    const earned = await stakingContract.earned(address, 0);

    const claimed = await stakingContract.getTotalRewardsClaimed(address, 0);

    
    const balanceInEther = ethers.utils.formatEther(balance);
    const roundedBalanceInEther = Math.floor(balanceInEther);
    const formattedBalance = roundedBalanceInEther.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits:2});

    const stakingBalanceInEther = ethers.utils.formatEther(stakingBalance);
    const roundedStakingBalanceInEther = Math.ceil(stakingBalanceInEther);
    const formattedStakingBalance = roundedStakingBalanceInEther.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits:2});

    const earnedInEther = ethers.utils.formatEther(earned);
    const formattedEarned = Math.abs(earnedInEther).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits:2});
    
    const claimedInEther = ethers.utils.formatEther(claimed);
    const formattedClaimed = Math.abs(claimedInEther).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits:2});
    
    setClaimedBalance(formattedClaimed)

    setKongBalanceRaw(roundedBalanceInEther)
    setStakeMax(roundedBalanceInEther)
    setStakeAmount(roundedBalanceInEther)
    setKongBalance(formattedBalance);

    setStakingBalanceRaw(roundedStakingBalanceInEther)
    setStakingBalance(formattedStakingBalance);
    setUnstakeAmount(roundedStakingBalanceInEther)

    setEarnedBalance(formattedEarned);
  }

  const loadEarned = async() => {
    const signer = web3Provider?.getSigner();
    const contract = new ethers.Contract("0x4c45bbec2ff7810ef4a77ad7bd4757c446fe4155", alphaAbi, signer);
    
    const stakingContract = new ethers.Contract(stakingAddress, stakingAbi, signer);
    
    const earned = await stakingContract.earned(address, 0);


    const earnedInEther = ethers.utils.formatEther(earned);
    const formattedEarned = Math.abs(earnedInEther).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits:2});

    setEarnedBalance(formattedEarned);
  }


  const stake = async () => {

    try{
      closeModal()
      const signer = web3Provider?.getSigner();
      const contract = new ethers.Contract(stakingAddress, stakingAbi, signer);
      const tokenContract = new ethers.Contract("0x4c45bbec2ff7810ef4a77ad7bd4757c446fe4155", alphaAbi, signer);

      
      const stakeAmountinEther = ethers.utils.parseEther(stakeAmount.toString());
      // Check if approval is necessary
      const allowance = await tokenContract.allowance(address, stakingAddress);
      if (allowance.lt(stakeAmountinEther)) {
        // Notify user about the approval request
        toast.info('Requesting Approval');
        
        // Approve the Staking Contract to spend the tokens
        const approveTx = await tokenContract.approve(stakingAddress, stakeAmountinEther);
        await approveTx.wait();

        // Notify user about the successful approval
        toast.success('Approval Successful');
      }

      const tx = await contract.stake(stakeAmountinEther,0)
      await tx.wait()
      await loadToken()
      toast.success('Transaction Success')
    } catch(err){
      console.log(err)
      toast.error('Transaction Failed')
    }
  }

  const unstake = async () => {

    try{
      closeModal()
      const signer = web3Provider?.getSigner();
      const contract = new ethers.Contract(stakingAddress, stakingAbi, signer);
      const tokenContract = new ethers.Contract("0x4c45bbec2ff7810ef4a77ad7bd4757c446fe4155", alphaAbi, signer);

      
      const stakeAmountinEther = ethers.utils.parseEther(unstakeAmount.toString());

      const tx = await contract.unstake(stakeAmountinEther,0)
      await tx.wait()
      await loadToken()
      toast.success('Transaction Success')
    }catch(err){
      console.log(err)
      toast.error('Transaction Failed')
    }
  }

  const claim = async () => {

    try{
      const signer = web3Provider?.getSigner();
      const contract = new ethers.Contract(stakingAddress, stakingAbi, signer);

      const tx = await contract.claimRewards(0)
      await tx.wait()
      await loadToken()
      toast.success('Successfully claimed $KONG')

      
      // let res = await axios.post(base_uri + '/grantRewardJngl', {}, 
      // {
      //   headers: {
      //     api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m'
      //   },
      //   withCredentials : true
      // })
    }catch(err){
      console.log(err)
      toast.error('Transaction Failed')
    }
  }


  return (
    <>
    <Header siteTitle={`Title`} updateBalance={updateBalance}/>
    <div
    id='Home' className='Marketplace' style={{marginTop:'100px'}}>
      <div className='Marketplace__content'>          
        <div className='marketplace-flex-container'>
          

          <div className='staking-banner-container'>
            <div style={{display:'flex'}}>
              <div className='marketplace-text-container-top'>
                  $JNGL Staking
              </div>
              {/* <div className='marketplace-text-container' style={{marginLeft:'auto'}}>
                  Your balance : <img ></img> {alphaBalance}
              </div> */}
            </div>
            <div style={{display:'flex'}}>
              <div className='marketplace-text-container-bottom'>
                  Go stake and find out!
              </div>
              {/* <div className='marketplace-text-container' style={{marginLeft:'auto'}}>
                  Your balance : <img ></img> {alphaBalance}
              </div> */}
            </div>
            <img src={banner} className='staking-banner-img'></img>

            <img src={banner} className='staking-banner-img-mobile'></img>
          </div>

          <div style={{display:'flex'}}>
            <div className='staking-logo-icon'>
              <img src={logo}></img>
            </div>
            <div className='marketplace-text-container-bottom-title' style={{alignSelf:'flex-end', fontSize:'24px',marginTop:'5px',marginLeft:'10px'}}>
              Stake here
            </div>
            {/* <div className='marketplace-text-container' style={{marginLeft:'auto'}}>
                Your balance : <img ></img> {alphaBalance}
            </div> */}
          </div>
          
          <div className='staking-items-container'>
            <div className='staking-item-card'>
              <img src={token1}>
              </img>
              <div className='staking-item-card-text'>
                  $JNGL STAKED
              </div>
              <div className='staking-item-card-amount'>
                  {stakingBalance}
              </div>
              <div className='staking-item-card-balance'>
                  AVAILABLE: {kongBalance} $JNGL
              </div>

              <div className='staking-item-card-info-button1'>
                  <div className='staking-item-card-info-button-buy' onClick={openStake}>
                    Stake
                  </div>


                  <div className='staking-item-card-info-button-supply' onClick={openUnstake}>
                    Unstake
                  </div>

              </div>
            </div>

            <div className='staking-item-card'>
              <img src={token2}>
              </img>
              <div className='staking-item-card-text'>
                  UNCLAIMED REWARDS
              </div>
              <div className='staking-item-card-amount'>
                  {earnedBalance}
              </div>
              <div className='staking-item-card-balance'>
                  TOTAL CLAIMED: {claimedBalance} $KONG
              </div>

              <div className='staking-item-card-info-button'>
                  <div className='staking-item-card-info-button-buy' onClick={claim}>
                    Claim rewards
                  </div>

              </div>

            </div>
           
          </div>

        </div>
      </div>
    </div>
    { showModal &&
      <div className='modal-stake-overlay'>
        <div className='modal-stake-content'>
          <button className='close-button' onClick={closeModal}>X</button>
          <h2 className='modalstake-title-txt'>Enter amount to stake</h2>
          <input
            type="number"
            value={stakeAmount}
            max={stakeMax}
            min={1}
            onChange={handleStakeAmountChange}
            placeholder="Enter amount"
            className='stake-input'
          />
          <button  className='modal-stake-button' onClick={stake}>Confirm Stake</button>
        </div>
      </div>
    }

    { showUnstakeModal &&
      <div className='modal-stake-overlay'>
        <div className='modal-stake-content'>
          <button className='close-button' onClick={closeModal}>X</button>
          <h2 className='modalstake-title-txt'>Enter amount to unstake</h2>
          <input
            type="number"
            value={unstakeAmount}
            onChange={handleUnStakeAmountChange}
            placeholder="Enter amount"
            className='stake-input'
          />
          <button className='modal-stake-button' onClick={unstake}>Confirm Unstake</button>
        </div>
      </div>
    }
    <footer className="footer">
        <div className="footer__section">
          <p className="footer__copyright">Copyright © 2023 $JNGL Token. All Rights Reserved.</p>
        </div>
        <div className="footer__section"> 
         <a href='https://etherscan.io/token/0x4C45bbEc2fF7810ef4a77ad7BD4757C446Fe4155'><img className="footer__icon" src={etherscan} alt="Icon 1"/></a>
          <a href='https://discord.com/invite/supremekong'><img className="footer__icon" src={discord} alt="Icon 1"/></a>
          <a href='https://twitter.com/Supremekongnft'><img className="footer__icon" src={twitter} alt="Icon 1"/></a>
          <a href='https://opensea.io/collection/supremekong'> <img className="footer__icon" src={opensea} alt="Icon 1"/></a>
          <a href='https://www.dextools.io/app/en/ether/pair-explorer/0xcd02a882804f3eb0f2d80f94a04defcea038359f'> <img className="footer__icon" src={dex} alt="Icon 1"/></a>
          <a href='mailto:cyrus@supremekong.com'> <img className="footer__icon" src={emailIcon} alt="Icon 1"/></a>
        </div>
    </footer>
    </>
  );
}
