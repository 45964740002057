import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Stats.scss';
import Header from '../../components/HeaderWallet/HeaderWallet';
import Preloader from '../../components/Preloader/Preloader';

const StatsPage = () => {
  const [stats, setStats] = useState({
    circulatingJNGL: 0,
    totalStakedWallets: 0,
    avgTokensPerWallet: 0,
    dailySpentJNGL: 0,
    averageDailyYieldPerUser: 0,
    dailySentJNGL: 0
  });

  const [loaded, setLoaded] = React.useState(false)

  const handleLoad = React.useMemo(() => {
    setLoaded(true)
  }, []);

  const base_uri = "https://api.supremekong.com"; // Update the base URI here
  //const base_uri = "http://localhost:8001";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m',
        };

        const response = await axios.get(`${base_uri}/getStats`, { headers }); // Use base URI here

        setStats({
          circulatingJNGL: response.data.circulating_supply,
          totalStakedWallets: response.data.staked_wallets,
          avgTokensPerWallet: response.data.avg_tokens,
          dailySpentJNGL: response.data.daily_spent,
          averageDailyYieldPerUser: response.data.avg_yield,
          dailySentJNGL: response.data.daily_sent_jngl
        });
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
    <Preloader handleLoad={handleLoad} />
    <Header siteTitle={`Title`} />
    <div className="stats-page">
      <h1>Statistics</h1>
      <div className="stat-item total-circulating">
        <h2>Total circulating $KONG</h2>
        <p className="total-circulating">{stats?.circulatingJNGL.toLocaleString()}</p>
        {/* Insert chart component here and pass relevant data as props */}
      </div>
      <div className="stat-item total-staked">
        <h2>Total staked wallets</h2>
        <p className="total-staked-wallets">{stats?.totalStakedWallets.toLocaleString()}</p>
        {/* Insert chart component here and pass relevant data as props */}
      </div>
      <div className="stat-item avg-wallet">
        <h2>Average $KONG per wallet</h2>
        <p className="token">{stats?.avgTokensPerWallet.toLocaleString()}</p>
        {/* Insert chart component here and pass relevant data as props */}
      </div>
      <div className="stat-item burn-wallet">
        <h2>$KONG being spent per day</h2>
        <p className="burn-wallet">{stats?.dailySpentJNGL.toLocaleString()}</p>
        {/* Insert chart component here and pass relevant data as props */}
      </div>
      
      <div className="stat-item sent-day">
        <h2>$KONG sent per day</h2>
        <p className='send'>{stats?.dailySentJNGL.toLocaleString()}</p>
        {/* Insert chart component here and pass relevant data as props */}
      </div>
      <div className="stat-item yield-day">
        <h2>$KONG yield per day</h2>
        <p className='yield'>{stats?.averageDailyYieldPerUser.toLocaleString()}</p>
        {/* Insert chart component here and pass relevant data as props */}
      </div>
    </div>
    </>
  );
};

export default StatsPage;
