import * as React from 'react';
import Logo from "../../assets/images/jngl-logo.png";
import ConnectButton from '../lib/ConnectWalletButton/ConnectButton';
import TwitterIcon from '../../assets/icons/twitter.svg';
import InstagramIcon from '../../assets/icons/instagram.svg';
import DiscordIcon from '../../assets/icons/discord.svg';
import navigateTo from '../../utils/navigateTo';
import { motion } from 'framer-motion';

import closeIcon from '../../assets/icons/closeYellow.svg';
import burgerIcon from '../../assets/icons/burger-header.png';
import './HeaderWallet.scss';

import { useWeb3Context } from '../../context'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import token2 from '../../assets/images/token-jngl.png'
import plus from '../../assets/images/plus.png'
import kongToken from '../../assets/images/kong-token.png'
import { ethers } from 'ethers'

const alphaAbi = require('../../helper/alpha.json')

const menuBtn = {
  open: { rotate: 0 },
  closed: { rotate: 180 },
};

const base_uri = "https://api.supremekong.com";
//const base_uri = 'http://localhost:8001'

const nav = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: '130%' },
};
const HeaderWallet = ({ siteTitle,balances }) => {
  let navigate = useNavigate()
  const tokens = localStorage.getItem('token');
  axios.defaults.headers.common['Authorization'] = `Bearer ${tokens}`;
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  const kongTokenAddress = "0x4c45bbec2ff7810ef4a77ad7bd4757c446fe4155"
  const [open, setOpen] = React.useState(false);
  const { web3Provider, connect, disconnect, address } = useWeb3Context()
  const [balance,setBalance] = React.useState(0)
  const [AlphaBalance,setAlphaBalance] = React.useState("0")
  const getBalance = async() => {
    const res = await axios.get(base_uri + '/getBalance?address='+address,
    {
    headers: {
        api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
    }
    })
    const roundedBalanceInEther = res.data.result[0].balance;
    const formattedBalance = roundedBalanceInEther.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2});
    setBalance(formattedBalance)
  }

  const loadToken = async() => {
    const signer = web3Provider?.getSigner();
    const contract = new ethers.Contract(kongTokenAddress, alphaAbi, signer);
    const balance = await contract.balanceOf(address);
    
    const balanceInEther = ethers.utils.formatEther(balance);
    const roundedBalanceInEther = Math.abs(balanceInEther);
    const formattedBalance = roundedBalanceInEther.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2});
    
    setAlphaBalance(formattedBalance);
  }

  function onNavigate(e) {
    navigateTo(e);
  }

  function handleOpen() {
    setOpen(!open);
  }

  React.useEffect(() => {
    if(web3Provider){
      getBalance()
      loadToken()
    }
  }, [web3Provider,balances]);

  React.useEffect(() => {
    if(balances){
      setBalance(balances)
    }else{
      getBalance()
    }
  }, [balances]);
  return (
    <header className='HeaderWallet'>
      <div className='HeaderWallet__content'>
        <div className='HeaderWallet__logo' >
          <div style={{display:"flex",}}>
            <a href='/staking'>
              <img src={Logo} alt='' />
            </a>
            <div style={{marginLeft:'40px',display:'flex',marginTop:'auto',marginBottom:'auto',gap:'20px'}}>
              <ConnectButton balances={{"balances":balance}}/>
            </div>
          </div>
        </div>
        
        <ul className='HeaderWallet__list'>
          {/* <li>
            <a href='/staking'>
              Stake NFT
            </a>
          </li>
          <li>
            <a href='/jngl/staking'>
              Stake $JNGL
            </a>
          </li> 
          <li>
            <a href='/marketplace'>
              $KONG Marketplace
            </a>
          </li>
          <li>
            <a href='/inventory'>
              Inventory
            </a>
          </li>
          <li>
            <a href='/transaction'>
              History
            </a>
          </li>
          <li>
            <a href='/faq'>
              FAQ
            </a>
          </li> */}
        </ul>
        <ul className='HeaderWallet__social'>
          {/* <a
            className='HeaderWallet__social__anchor'
            href='https://twitter.com/Supremekongnft'
            target='_blank'
            rel='noreferrer'
          >
            <img src={TwitterIcon} alt='' />
          </a>
          <a
            className='HeaderWallet__social__anchor'
            href='https://instagram.com/supremekong.nft'
            target='_blank'
            rel='noreferrer'
          >
            <img src={InstagramIcon} alt='' />
          </a>
          <a
            className='HeaderWallet__social__anchor'
            href='https://discord.com/invite/supremekong'
            target='_blank'
            rel='noreferrer'
          >
            <img src={DiscordIcon} alt='' />
          </a> */}
          <div
            className='HeaderWallet__social__anchor'
            className='header-border'
            href='https://discord.com/invite/supremekong'
            target='_blank'
            rel='noreferrer'
          >
            {address &&
              address.substring(0,3) + "..."+ address.substring(address.length-3,address.length)
            }
          </div>
          { address  &&
            <div
              className='HeaderWallet__social__anchor'
              className='header-border-btn'
              href='https://discord.com/invite/supremekong'
              target='_blank'
              rel='noreferrer'
            >
              <button className='DiscordButton' onClick={disconnect} style={{paddingLeft:"25px",paddingRight:"25px"}}>
                Disconnect
              </button>
            </div>
          }
        
        </ul>
        <motion.button
          animate={open ? 'open' : 'closed'}
          initial={'closed'}
          variants={menuBtn}
          onClick={handleOpen}
          className='HeaderWallet__menu__btn'
        >
          <img src={open ? closeIcon : burgerIcon} alt='' />
        </motion.button>
      </div>
      <motion.nav
        animate={open ? 'open' : 'closed'}
        initial={'closed'}
        variants={nav}
        onClick={handleOpen}
        className='Header__menu__btn'
        className='Header__motion__nav'
      >
        <ul className='Header__motion__nav-anchors'>
          <li className='header-mobile' style={{textAlign:"center",fontSize:"20px",display:'none'}}>
            {address &&
              address.substring(0,5) + "..."+ address.substring(address.length-5,address.length)
            }
            { address &&
                <div className='header-mobile' style={{marginLeft:'40px',marginTop:'20px',marginBottom:'auto',gap:'20px',display:'none'}}>
                  <div  style={{display:'flex',placeContent:'center'}} data-tooltip-id="my-tooltip" data-tooltip-content="$JNGL">
                    <a href='https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0x4c45bbec2ff7810ef4a77ad7bd4757c446fe4155' target='_blank'><img src={plus} style={{width:"40px",height:"40px"}}></img></a>
                    <div className='balance-display' style={{fontSize:"20px"}}>{AlphaBalance}</div> <img src={token2} width={40} height={40} ></img>
                  </div>
                  <div  style={{display:'flex',placeContent:'center'}} data-tooltip-id="my-tooltip" data-tooltip-content="$KONG">
                    <div style={{height:'44px',width:'40px'}}></div>
                    <div className='balance-display' style={{fontSize:"20px"}}>{balance}</div> <img src={kongToken} width={40} height={40} ></img> 
                  </div>
                </div>
              }
          </li> 
          { web3Provider &&
          <li className='header-mobile' style={{textAlign:"center",fontSize:"20px",display:'none'}} >
              <button className='DiscordButton' onClick={disconnect} style={{paddingLeft:"25px",paddingRight:"25px",marginLeft:"auto",marginRight:"auto"}}>
                Disconnect
              </button>
          </li> 
          }
          <li>
              <a href='https://supremekong.com/'>
                Home
              </a>
          </li>
          <li >
            <a onClick={() => navigate('/staking')} /* Update the href to use navigate */>
              Stake NFT
            </a>
          </li> 
          <li>
            <a onClick={() => navigate('/jngl/staking')} /* Update the href to use navigate */>
              Stake $JNGL
            </a>
          </li> 
          <li>
            <a onClick={() => navigate('/marketplace')} /* Update the href to use navigate */>
              $KONG Marketplace
            </a>
          </li>
          <li>
            <a onClick={() => navigate('/jngl/marketplace')} /* Update the href to use navigate */>
              $JNGL Marketplace
            </a>
          </li>
          <li>
            <a onClick={() => navigate('/inventory')} /* Update the href to use navigate */>
              Inventory
            </a>
          </li>
          <li>
            <a onClick={() => navigate('/check-level')} /* Update the href to use navigate */>
             Check Level
            </a>
          </li>
          <li>
            <a onClick={() => navigate('/check-jngl')} /* Update the href to use navigate */>
             Check $JNGL
            </a>
          </li>
          <li>
            <a onClick={() => navigate('/transaction')} /* Update the href to use navigate */>
              History
            </a>
          </li>
          <li>
            <a onClick={() => navigate('/faq')} /* Update the href to use navigate */>
              FAQ
            </a>
          </li>
          {/* <li>
            <a href='/mutate'>
              Mutate
            </a>
          </li> */}
        </ul>
        {/* <ul className='Header__social'>
          <a
            className='Header__social__anchor'
            href='https://twitter.com/Supremekongnft'
            target='_blank'
            rel='noreferrer'
          >
            <img src={TwitterIcon} alt='' />
          </a>
          <a
            className='Header__social__anchor'
            href='https://discord.com/invite/supremekong'
            target='_blank'
            rel='noreferrer'
          >
            <img src={DiscordIcon} alt='' />
          </a>
        </ul> */}
      </motion.nav>
    </header>
  );
};

export default HeaderWallet;
