import * as React from "react";

import "./Mutate.scss";
import DiscordButton from "../../components/lib/DiscordButton/DiscordButton";
import Preloader from "../../components/Preloader/Preloader";
import Header from "../../components/HeaderWallet/HeaderWallet";
import { useWeb3Context } from "../../context";
import { useNavigate, useParams } from "react-router-dom";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import axios from "axios";
import arrow from "../../assets/images/arrow.png";
import ProgressBar from "../../components/Progressbar/Progressbars";
import { fail } from "assert";
import { HmacSHA256, enc } from "crypto-js";
import mutantbanana from "../../assets/images/supreme-bananas.jpeg";
import kongRedeemAbi from "../../helper/kongRedeem.json";

const mutantbananaAbi = require("../../helper/mutantBanana.json");
const stakingAbi = require("../../helper/stakeAbi.json");
const bananaAbi = require("../../helper/bananaAbi.json");

let interval;

function CheckKong() {
  const tokens = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = `Bearer ${tokens}`;
  axios.defaults.headers.common["Content-Type"] = "application/json";
  let navigate = useNavigate();

  let { id } = useParams();

  //const base_uri = "http://localhost:8001";
  const base_uri = "https://api.supremekong.com";

  const bananaContract = process.env.REACT_APP_BANANA_CONTRACT;
  const mutantBananaContract = process.env.REACT_APP_MUTANT_BANANA_CONTRACT;
  const stakingContract = process.env.REACT_APP_STAKING_CONTRACT;

  const burnCost = 5;

  const arrayCosts = [1, 2, 3, 5, 6, 8, 10, 12, 14, 18];

  const arrayBonus = [
    "0%",
    "2%",
    "4%",
    "6%",
    "8%",
    "10%",
    "15%",
    "25%",
    "40%",
    "65%",
    "100%",
  ];

  const { web3Provider, connect, disconnect, address } = useWeb3Context();

  const [loaded, setLoaded] = React.useState(false);

  const [owner, setOwner] = React.useState(false);

  const [potion, setPotion] = React.useState(0);

  const [load, setLoad] = React.useState(false);

  const [yieldJngl, setYieldJngl] = React.useState(0);

  const [chance, setChance] = React.useState(0);

  const [eligible, setEligible] = React.useState(true);

  const [maxAmount, setMaxAmount] = React.useState(0);

  const [amount, setAmount] = React.useState(0);

  const [approvedBanana, setApprovedBanana] = React.useState(false);

  const [mintVisible, setMintVisible] = React.useState(true);

  const countDownDate = new Date("2022-08-31T22:00:00.000-04:00").getTime();
  const [showModal, setShowModal] = React.useState(true);

  const [countDown, setCountDown] = React.useState(
    countDownDate - new Date().getTime()
  );

  const [bananaNft, setbananaNft] = React.useState([]);

  const [leveling, setLeveling] = React.useState(false);

  const [failed, setFailed] = React.useState(false);

  const [success, setSuccess] = React.useState(false);

  const [progress, setProgress] = React.useState(false);

  const [progressRate, setProgressRate] = React.useState(0);

  const [day, setDay] = React.useState(0);
  const [hour, setHour] = React.useState(0);
  const [minute, setMinute] = React.useState(0);
  const [second, setSecond] = React.useState(0);

  const [timer, setTimer] = React.useState("00:00:00");

  const startTime = 1696663800;

  const redeemContractAddress = "0x22d138e08f3AbA618A34300Bc0caCf16D6dC1014";

  const handleLoad = React.useMemo(() => {
    setLoaded(true);
  }, []);

  React.useEffect(async () => {
    if (web3Provider) {
      await loadTimer();
    }
  }, [web3Provider]);

  function formatCountdown(timestamp) {
    const cutoffDate = new Date("2024-08-06T00:00:00Z"); // Current timestamp in seconds
    const oneWeekInSeconds = 7 * 24 * 3600; // 1 week in seconds
    const targetTimestamp = timestamp + oneWeekInSeconds; // Calculate 1 week after the claimed timestamp

    const timeDifference = targetTimestamp - cutoffDate; // Calculate the difference in seconds

    if (timeDifference <= 0) {
      return "00:00:00"; // Handle the case when the target time has already passed
    }

    const days = Math.floor(timeDifference / 86400); // 86400 seconds in a day
    const hours = Math.floor((timeDifference % 86400) / 3600); // 3600 seconds in an hour
    const minutes = Math.floor((timeDifference % 3600) / 60); // 60 seconds in a minute
    const seconds = timeDifference % 60;

    const formattedTime = `${String(days).padStart(2, "0")}:${String(
      hours
    ).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(
      seconds
    ).padStart(2, "0")}`;

    return formattedTime;
  }

  const loadTimer = async () => {
    console.log("called");
    try {
      const signer = web3Provider.getSigner();
      const kongRedeem = new ethers.Contract(
        redeemContractAddress,
        kongRedeemAbi,
        signer
      );

      let timestamp = await kongRedeem.userLastClaimTimestamp(id, 0);

      console.log("timestamp", timestamp.toNumber());

      timestamp = timestamp.toNumber();
      const cutoffDate = Math.floor(
        new Date("2024-08-06T00:00:00Z").getTime() / 1000
      );

      const yields = 11.11;

      if (timestamp === 0) {
        const timeDiffInSeconds = cutoffDate - startTime;
        const oneDayInSeconds = 24 * 3600;
        const resultYield = (timeDiffInSeconds / oneDayInSeconds) * yields;
        setYieldJngl(resultYield);
      } else {
        const timeDiffInSeconds = cutoffDate - timestamp;
        const oneDayInSeconds = 24 * 3600;
        const resultYield = (timeDiffInSeconds / oneDayInSeconds) * yields;
        setYieldJngl(resultYield);
      }

      const updateCountdown = () => {
        const countdown = formatCountdown(timestamp);
        setTimer(countdown);

        if (timestamp < 0) {
          clearInterval(interval); // Stop the countdown when it reaches 00:00:00:00
        }
      };

      // Initial update
      updateCountdown();

      // Set up the interval to update the countdown every second
      const interval = setInterval(updateCountdown, 1000);

      // Clean up the interval when the component unmounts
      return () => clearInterval(interval);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Preloader handleLoad={handleLoad} />
      <Header siteTitle={`Title`} />
      <div className="Mint__content">
        {/* <motion.h1 
        {...paragraphAnimation}
        className='Mint__content__title Title'>THE SUPREME LEADERS OF THE METAVERSE</motion.h1> */}
        {!leveling && (
          <>
            <div className="level-container">
              <p>CHECK $JNGL CLAIM</p>

              <p style={{ fontSize: "14px", marginTop: "15px" }}>
                Supreme Kong #{id}
              </p>

              <div className="level-change">
                <div className="level-before">
                  <img
                    src={"https://raffle.supremekong.com/images/" + id + ".png"}
                  ></img>
                  <div>TAX TIMER</div>
                  <div>{timer}</div>
                  <div>Available</div>
                  <div>{yieldJngl.toFixed(3)} $JNGL</div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default CheckKong;
