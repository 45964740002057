import React from 'react';

import { Tooltip } from 'react-tooltip'
import DiscordIcon from '../../../assets/icons/discord.svg';
import './DiscordButton.scss';
import { useWeb3Context } from '../../../context'
import axios from "axios";
import token2 from '../../../assets/images/kong-token.png'
import token1 from '../../../assets/images/token-jngl.png'
import plus from '../../../assets/images/plus.png'
import { ethers } from 'ethers'
const alphaAbi = require('../../../helper/alpha.json')

function ConnectWalletButton(props) {
  const kongTokenAddress = "0x4c45bbec2ff7810ef4a77ad7bd4757c446fe4155"
  const base_uri = "https://api.supremekong.com";
  const [AlphaBalance,setAlphaBalance] = React.useState("0")
  const { web3Provider, connect, disconnect, address } = useWeb3Context()

  const [balance,setBalance] = React.useState(0)

  const getBalance = async() => {
    const res = await axios.get(base_uri + '/getBalance?address='+address,
    {
    headers: {
        api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
    }
    })
    setBalance(res.data.result[0].balance)
  }
  React.useEffect(() => {
    if(props){
      setBalance(props.balances.balances)
      loadToken()
    }
  }, [props]);

  React.useEffect(() => {
    if(web3Provider){
      loadToken()
    }
  }, [web3Provider]);


  const loadToken = async() => {
    const signer = web3Provider?.getSigner();
    const contract = new ethers.Contract(kongTokenAddress, alphaAbi, signer);
    const balance = await contract.balanceOf(address);
    
    const balanceInEther = ethers.utils.formatEther(balance);
    const roundedBalanceInEther = Math.abs(balanceInEther);
    const formattedBalance = roundedBalanceInEther.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2});
    
    setAlphaBalance(formattedBalance);
  }


  return (
    // <button className='DiscordButton'>
    //     {text}
    // </button>

    web3Provider ? (
      // <button className='DiscordButton' onClick={disconnect}>
      //    Disconnect
      // </button>
      <>
      
      <div className='header-desktop' style={{display:"flex",marginLeft:"10px"}} data-tooltip-id="my-tooltip" data-tooltip-content="$JNGL">
         <a href='https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0x4c45bbec2ff7810ef4a77ad7bd4757c446fe4155' target='_blank'><img src={plus} style={{width:"40px",height:"40px"}}></img></a>
        {/*<p style={{marginTop:"auto",marginBottom:"auto",marginLeft:"5px"}}>
          {AlphaBalance}
        </p> */}
        <div className='balance-display' style={{fontSize:"20px"}}>{AlphaBalance}</div> <img src={token1} width={40} height={40} ></img>
      </div>
      <div className='header-desktop' style={{display:"flex",marginLeft:"10px"}} data-tooltip-id="my-tooltip" data-tooltip-content="$KONG">
        {/* <img src={token2} style={{width:"30px",height:"30px"}}></img>
        <p style={{marginTop:"auto",marginBottom:"auto",marginLeft:"5px"}}>
          {balance.toLocaleString()}
        </p> */}
        <div className='balance-display' style={{fontSize:"20px"}}>{balance.toLocaleString()}</div> <img src={token2} width={40} height={40} ></img> 
      </div>
      <Tooltip id="my-tooltip" />
      </>
    ) : (
      <></>
    )
  );
}
export default ConnectWalletButton;
