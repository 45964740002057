import * as React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Web3ContextProvider, useWeb3Context } from './context'
import {ToastContainer,toast} from 'react-toastify'
import Faq from './pages/Faq/Faq';
import Home from './pages/home/home';
import HomeNew from './pages/home-new/home';
import HomeJungle from './pages/home-jungle/home';
import Mint from './pages/Mint/Mint';
import Staking from './pages/Staking/Staking';
import Vesting from './pages/Vesting/Vesting';
import VestingMb from './pages/VestingMb/Vesting';
import VestingSb from './pages/VestingSb/Vesting';
import VestingSk2 from './pages/VestingSk2/Vesting';
import Mutate from './pages/MutateSold/MutateSold';
import Connect from './pages/Connect/Connect';
import Marketplace from './pages/Marketplace/Marketplace';
import MarketplaceErc from './pages/MarketplaceErc/MP';
import StakingErc from './pages/StakingErc/MP';
import StakingNft from './pages/StakingTemp/Staking';
import RaffleErc from './pages/RaffleErc/MP';
import DetailRaffleErc from './pages/DetailRaffleErc/MP';
import Leveling from './pages/Leveling/leveling';
import LevelingGen2 from './pages/Level-gen2/leveling';
import LevelingKong from './pages/Level-kong/leveling';
import LevelingBanana from './pages/Level-banana/leveling';
import Inventory from './pages/Inventory/Inventory';
import Transaction from './pages/Transaction/Transaction'
//import Stats from './pages/Stats/Stats'
import CheckKong from './pages/CheckKongClaim/ChecKong';
import CheckLevelDetail from './pages/CheckLevelDetail/leveling';
import CheckLevel from './pages/CheckLevel/leveling';
import CheckJngl from './pages/CheckJngl/leveling';
//import InventoryTest from './pages/InventoryTest/Inventory';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

function App() {

  const { web3Provider, connect, disconnect, address } = useWeb3Context()

  return (
    <Web3ContextProvider>
      <div className="App">
      <Router>
        <Routes>
          
          <Route path="/" element={<HomeNew />} />
          <Route path="/home" element={<Home />} />
          {/* <Route path="/jungle" element={<HomeJungle />} /> */}
          <Route path="/staking" element={<Staking />} /> 
          {/* <Route path="/staking-test" element={<StakingTest />} />  */}
          <Route path="/mutate" element={<Mutate />} />
          <Route path="/connect" element={<Connect />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/marketplace" element={<Marketplace />} /> 
          <Route path="/jngl/marketplace" element={<MarketplaceErc />} /> 
          <Route path="/jngl/staking" element={<StakingErc />} /> 
          <Route path="/jungle/stakingnft" element={<StakingNft />} /> 
          {/* <Route path="/sk1jnglclaim" element={<Vesting />} /> 
          <Route path="/sk2jnglclaim" element={<VestingSk2 />} /> 
          <Route path="/sbjnglclaim" element={<VestingSb />} /> 
          <Route path="/mbjnglclaim" element={<VestingMb />} />  */}
          <Route path="/jungle/raffle" element={<RaffleErc />} /> 
          <Route path="/jungle/raffle/:id" element={<DetailRaffleErc />} />
          <Route path="/inventory" element={<Inventory />} /> 
          <Route path="/transaction" element={<Transaction />} /> 
          {/* <Route path="/stats" element={<Stats />} /> */}
          {/* <Route path="/inventory-test" element={<InventoryTest />} />  */}
          <Route path="/level-up/supreme-mutant-bananas/:id" element={<Leveling />} /> 
          <Route path="/level-up/supreme-kong-2/:id" element={<LevelingGen2 />} /> 
          <Route path="/level-up/supreme-kong/:id" element={<LevelingKong />} />
          <Route path="/level-up/supreme-banana/:id" element={<LevelingBanana />} />
          <Route path="/check-jngl/:id" element={<CheckKong />} />
          <Route path="/check-level/:type/:id" element={<CheckLevelDetail />} />
          <Route path="/check-level" element={<CheckLevel />} />
          <Route path="/check-jngl" element={<CheckJngl />} />
        </Routes>
      </Router>
      <ToastContainer
      position="bottom-right"/>
      </div>
    </Web3ContextProvider>
  );
}

export default App;
